.wrapper .content {
  display: flex;
  flex-direction: column;
}

.buttons {
  margin-top: 28px;

  display: flex;
  justify-content: flex-end;
  > *:not(:last-child) {
    margin-right: 18px;
  }
}

@celsia-white: #FFFFFF;@celsia-semi-white: #F4F4F4;@celsia-dark: #2D2B2B;@celsia-orange: #FF812D;@celsia-gray: #E9E9E9;@celsia-background-light: #FAFAFA;@celsia-background-medium: #E7E7E7;@celsia-background-dark: #C3C3C3;@celsia-blue: #49A2D8;@celsia-sea-water: #49DCB1;@celsia-dark-blue: #39587D;@celsia-light-gray: #828282;@celsia-red-alert: #CA0045;@primary-color: #376680;@secundary-color: #49A2D8;@success-color: #01C08B;@processing-color: #FFF649;@error-color: #F60141;@normal-color: #E7E7E7;@text-color: #3F3F3F;@standard-padding: 16px 24px;@menu-bg: #F5F6F7;@card-padding-base-sm: @card-padding-base / 2;@card-padding-base: 24px;@fontcustom: #fff;@font-family: "Raleway", sans-serif;@border-radius-base: 10px;