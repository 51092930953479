@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
#main {
  position: absolute;
  width: 100%;
  height: 100%;
}
#main .menu-wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  overflow: hidden;
  background-color: #F5F6F7;
}
#main .menu-wrapper li.ant-menu-item,
#main .menu-wrapper div.ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
#main .menu-wrapper .ant-menu-submenu-selected {
  color: #376680;
}
#main .menu-wrapper span.anticon {
  font-size: 21px;
}
#main .menu-wrapper__menu {
  overflow-y: auto;
  flex: 1 1;
}
#main .menu-wrapper__menu--collapsed {
  overflow-y: auto;
  flex: 1 1;
}
#main .menu-wrapper__logo {
  background-color: #376680;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#main .menu-wrapper__logo--hide-logo {
  display: none;
}
#main .menu-wrapper__logo img {
  color: #376680;
  margin: 0;
  width: auto;
  height: 100%;
  object-fit: contain;
}
#main .content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#main .content-wrapper__header {
  background-color: #376680;
  display: flex;
  align-items: center;
  padding: 0;
  height: 60px;
  z-index: 1;
}
#main .content-wrapper__header > div {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
#main .content-wrapper__main-content {
  position: relative;
  overflow-y: auto;
}
.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.language_background .ant-select {
  color: white;
  background-color: transparent !important;
  width: 100%;
}
.language_background .ant-select .ant-select-selector {
  background-color: transparent !important;
}
.language_background .ant-select .ant-select-arrow {
  color: white;
}
.language_background .dark-style {
  color: gray;
  width: 60px;
}
.language_background .dark-style .ant-select-selector {
  background-color: transparent !important;
}
.language_background .dark-style .ant-select-arrow {
  color: gray;
}

#breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 16px 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
#breadcrumb > * {
  margin: 0;
}

.tarjetas-table {
  padding: 16px 24px;
  display: flex;
  width: 100%;
}
.tarjetas-table .container-resume-row {
  width: 100%;
}
.tarjetas-table .container-resume-row .container-row-resumen {
  margin-bottom: 20px;
}
.tarjetas-table .container-resume-row .container-row-resumen .container-resumen {
  background-color: white;
  padding: 15px !important;
  box-sizing: border-box;
}
.tarjetas-table .container-resume-row .container-row-resumen .container-resumen .value-resumen {
  float: right;
}
.tarjetas-table .container-resume-row .tarjetas_body {
  margin-top: 20px;
}
.tarjetas-table .container-resume-row .tarjetas_body .ant-table-content {
  overflow-x: auto;
}
.tarjetas-table .container-resume-row .range-selector {
  margin: 10px 0px 0px 0px;
}
.tarjetas-table .tarjetas-options-right {
  display: flex;
  justify-content: flex-end;
}
.tarjetas-table .tarjetas-options .ant-col:nth-child(n+1) {
  margin: 0px 10px 0px 0px !important;
}
.tarjetas-table .tarjetas-options .ant-col:nth-child(n+1) button {
  margin: 0px !important;
}
.tarjetas-table .btn_lista_blanca {
  margin-left: auto;
  margin-right: 20px;
}
.top_table {
  display: flex;
}
.ant-select-selection-item {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
@media (max-width: 670px) {
  .tarjetas-options-right {
    display: flex;
    justify-content: flex-end;
  }
  .tarjetas-options-right .ant-row {
    display: flex;
    justify-content: flex-end;
  }
  .tarjetas-options-right .ant-row .ant-col button {
    margin-right: 0px;
  }
}
@media (max-width: 1060px) {
  .tarjetas-table .tarjetas-options .ant-col:nth-child(2n+2) {
    margin: 10px 10px 0px 0px !important;
  }
}
@media (max-width: 1300px) {
  .tarjetas-table .tarjetas-options .ant-col:nth-child(3) {
    margin: 10px 0px 0px 0px !important;
  }
}

#in-progress-view {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#in-progress-view .title {
  margin-top: 12px;
}

.bottomMsg {
  margin: 15px 0px 0px 0px;
  font-size: 1.3em;
}
.bottomCount {
  text-align: right;
  margin: 15px 0px 0px 0px;
  font-size: 1.3em;
}
.card-default {
  height: 100%;
}
.card-default .ant-card-body {
  padding: 19px;
}
.card-default_divider {
  margin-bottom: 0;
}
.card-default_title {
  font-weight: bold;
  font-size: 1.3em;
}
.card-default_mainvalue {
  font-size: 2em;
  font-weight: bold;
}
.card-default_info {
  text-align: right;
  font-size: 18px;
}
.metricas-card_children {
  font-size: 1.2em;
}
.metricas-card_children_porc {
  margin: 0px 5px 0px 15px;
}
.metricas-card_icon_float {
  display: inline-block;
  font-size: 1.3em;
  vertical-align: -webkit-baseline-middle;
}
.card-default {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}
.card-default .ant-card-body {
  height: 100%;
  max-width: 100%;
  width: 100%;
}
.card-default .ant-card-body .ant-spin {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-category {
  min-height: 242px;
}
.card-category_icon {
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: 4em;
  margin: 20px auto 0px auto;
}
.card-category_mainvalue {
  text-align: center;
  font-size: 3em;
  font-weight: bolder;
  margin-bottom: 0px;
}
.card-category_description {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 0px;
}
.card-category {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-category .ant-card-body {
  height: 100%;
}
.card-category .ant-card-body .ant-spin {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-graphic_title {
  font-size: 1.3em;
  font-weight: bolder;
}
.metrica-bar-horizontal {
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 50px;
}
.metrica-bar-horizontal .x-axis-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-user-select: none;
          user-select: none;
}
.metrica-bar-horizontal .x-axis-wrapper .svg-custom-xaxis {
  position: absolute;
  padding: 0px 22px 0px 114px;
  height: 25px;
  bottom: 23px;
}

.card-energy {
  padding: 0px 10px 0px 10px;
}
.card-energy .ant-table-wrapper {
  height: 100%;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading {
  height: 100%;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  height: 100%;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container {
  height: 100%;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  height: 100%;
  overflow-x: auto;
}
.card-energy .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table {
  height: 100%;
}
.card-energy .energy-title {
  font-weight: bold;
  font-size: 1.3em;
}
.card-energy .x-axis-wrapper {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
}
.card-energy .x-axis-wrapper .recharts-surface {
  padding-right: 30px;
  padding-left: 138px;
  overflow-x: overlay;
  height: 50px;
}
.card-energy .div-graphic {
  min-height: 390px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 45px;
}
.card-energy .div-graphic .empty-graph-energy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.card-energy .div-graphic .recharts-default-legend {
  display: flex;
  justify-content: center;
}
.card-energy .energy-title {
  text-align: center;
}
@media (min-width: 1024px) {
  .metricas-card_data .ant-card {
    height: 100% !important;
  }
}
.card-energy {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-energy .ant-card-body {
  width: 100%;
  height: 100%;
}
.card-energy .ant-card-body .ant-spin {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-cake {
  height: 100%;
  position: relative;
  z-index: 1;
}
.card-cake .background_table {
  position: absolute;
  left: 0;
  padding: 0px 24px 0px 24px;
}
.card-cake .energy-title {
  font-weight: bold;
  font-size: 1.3em;
}
.card-cake .empty-cake {
  position: absolute;
  left: 50%;
  top: 50%;
}
.card-cake .loading-cake {
  position: absolute;
  left: 50%;
  top: 50%;
}
.card-cake .graphic-cake svg {
  overflow: visible!important;
  position: relative;
  z-index: 0;
}
.card-cake .graphic-cake .recharts-legend-wrapper {
  overflow: auto;
  height: 100% !important;
}
.card-cake .uso-tooltip {
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 1px 1px 5px;
}
.card-cake .uso-tooltip .location {
  color: #52a7da;
  font-size: 12px;
  margin-bottom: 0px;
}
.card-cake .uso-tooltip .evse {
  font-weight: bolder;
  font-size: 16px;
}
.card-cake .uso-tooltip .type {
  font-weight: bolder;
  margin-bottom: 0px;
}
.count-evses {
  fill: #3f3f3f !important;
}

.table-recharges .ant-table-content {
  overflow-x: auto;
}

.metricas-row_main {
  margin-bottom: 40px;
}
.metricas-row_main .metricas-content {
  width: 100%;
  margin: 0px !important;
}
.metricas-row_main .metricas-content .metricas-col {
  padding: 20px;
}
.metricas-row_main .metricas-content .metricas-col .filtro-col {
  padding: 0px 10px 0px 10px;
}
.metricas-row_main .metricas-content .metricas-col .filtro-col .metricas-form_filtro {
  margin-bottom: 0;
}
.metricas-row_main .metricas-content .metricas-col .metricas-card_data {
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 0px 0px;
}
.metricas-row_main .metricas-content .metricas-col .metricas-card_data .empty-energia_entregada {
  position: absolute;
  width: 100%;
  margin: 0px auto 0px auto;
}
.metricas-row_main .metricas-content .metricas-col .con-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 0px;
  top: -100px;
}
.metricas-row_main .metricas-content .metricas-col .con-container .connector-selector {
  width: 150px;
  margin-bottom: 10px;
}
.metricas-row_main .metricas-content .metricas-col > .ant-row:nth-child(n+2) {
  margin-top: 30px;
}
.metrica-bar-horizontal > .recharts-surface {
  padding-right: 30px;
  overflow-x: overlay;
}
.metricas-card_children_value {
  text-align: right;
}
.metricas-card_tooltip {
  position: absolute;
  right: 0px;
  top: -100%;
  font-size: 18px;
  cursor: pointer;
}
.metricas-tooltip {
  padding: 10px 15px 10px 15px ;
  width: 300px;
  color: black;
}
.metricas-tooltip .metricas-tooltip_title {
  font-size: 1.3em;
}
.metricas-tooltip .metricas-tooltop_legend {
  color: #ee7400;
}
@media (min-width: 600px) {
  .metricas-space {
    margin-top: 10px;
  }
}
.w-100 {
  width: 100%;
}
.pr-1 {
  padding-right: 8px;
}
@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 8px;
  }
}
.pl-1 {
  padding-left: 8px;
}
@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 8px;
  }
}
@media (max-width: 767px) {
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .pt-sm-1 {
    padding-top: 8px;
  }
}
@media (max-width: 767px) {
  .pb-sm-1 {
    padding-bottom: 8px;
  }
}
.ant-select-multiple .ant-select-selector {
  max-height: 30px;
  overflow: auto;
}
.ant-select-selector ::-webkit-scrollbar {
  display: none;
}
.filtro-col *::-webkit-scrollbar {
  display: none;
}
@media (max-width: 767px) {
  .filtro-col {
    width: 100%;
  }
}
.filtro-col .metricas-form_filtro {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.filtro-col .metricas-form_filtro .ant-form-item-label {
  max-width: 65px !important;
}
.filtro-col .metricas-form_filtro .ant-form-item-control {
  max-width: calc(100% - 55px) !important;
}
.d-flex {
  display: flex !important;
}
.container-select-multiple {
  margin-left: 0.5rem;
}
.container-select-multiple .multi-select {
  width: 250px;
}
.container-select-multiple .dropdown-container {
  border-radius: 10px;
  height: 32px;
}
.container-select-multiple .dropdown-container .dropdown-heading {
  border-radius: 10px;
  height: 32px;
}

.alertas-table {
  padding: 16px 24px;
}
.alertas-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  overflow-x: auto;
}
.ant-select-selection-item {
  padding-left: 10px !important;
  padding-right: 30px !important;
}
@media (max-width: 714px) {
  .date-selector {
    margin-bottom: 10px;
  }
}

#single-info-charger {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  padding: 16px 24px;
}
#single-info-charger .charger-info__data-item {
  display: flex;
}
#single-info-charger .charger-info__data-item > * {
  margin: 0;
}
#single-info-charger .charger-info__data-item > *:first-child {
  margin-right: 4px;
}
#single-info-charger .charger-info__footer-item {
  position: relative;
}
#single-info-charger .card-connector-info__data-item {
  display: flex;
}
#single-info-charger .card-connector-info__data-item > * {
  margin: 0;
}
#single-info-charger .card-connector-info__data-item > *:first-child {
  margin-right: 4px;
}
#single-info-charger .card-connector-info__footer-item {
  position: relative;
}
#single-info-charger .dot {
  height: 10px;
  width: 10px;
  background-color: #6dd7a9;
  border-radius: 50%;
  display: inline-block;
}
.tabla-recargas .recargas_filtro {
  margin-bottom: 10px;
}
.tabla-recargas .recargas_tablas .ant-table-content {
  width: 100%;
  overflow-x: auto;
}
.tabla-recargas .recargas_tablas tbody {
  background-color: white;
}
.tabla-recargas .btn-close-chart {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.tabla-recargas .graphic-title {
  font-size: 16px;
  font-weight: 500;
}
.cargador-row-container {
  padding: 30px;
}
.card_comandos .container-btn-sendcommand > div {
  margin: auto;
}
.card_comandos .ant-form-item-label > label::before {
  visibility: hidden;
}
.card_comandos .ant-form-item-label > label::after {
  visibility: hidden;
}
.card_comandos .ant-form-item:last-child {
  margin-bottom: 0px;
}
.tabla-conectores .ant-table-content {
  width: 100%;
  overflow-x: auto;
}
.tabla-conectores tbody {
  background-color: white;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}

.card-map-info {
  text-align: center;
  cursor: pointer;
  border: 2px solid #f0f0f0;
}
.card-map-info .ant-card-body {
  padding: 16px !important;
  width: 120px;
  height: 120px;
}
.card-map-info .ant-card-body .ant-row {
  margin: 0px !important;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-map-info .card-title {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
}

#tablero-general-cpo_mapa {
  height: 100%;
  overflow-y: hidden;
}
#tablero-general-cpo_mapa .col-map {
  min-height: 400px;
  height: calc(100% - 56px);
}
#tablero-general-cpo_mapa .col-map .card-info-container {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}
#tablero-general-cpo_mapa .col-map .card-info-container-resume {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
#tablero-general-cpo_mapa .col-map .card-info-table {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  bottom: 0px;
  left: 0px;
}
#tablero-general-cpo_mapa .col-map .card-info-table .icon-table_acciones {
  font-size: 18px;
  margin: 0px 5px 0px 5px;
}
#tablero-general-cpo_mapa .col-map .card-info-table .ant-drawer .ant-drawer-content-wrapper {
  float: left;
  height: auto;
}
#tablero-general-cpo_mapa .col-map .card-info-table .ant-drawer .ant-drawer-content-wrapper .ant-drawer-body {
  padding: 0px;
  justify-content: flex-start !important;
}
#tablero-general-cpo_mapa .col-map .card-info-table .ant-drawer .ant-drawer-content-wrapper .ant-drawer-body .filters-container {
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 1;
  display: flex;
}
#tablero-general-cpo_mapa .col-map .card-info-table .ant-drawer .ant-drawer-content-wrapper .ant-drawer-body .filters-container .btn-download-csv {
  margin: 0px 0px 0px 10px;
}
#tablero-general-cpo_mapa .contenedor-mapa {
  min-height: 532px;
  position: relative;
}
#tablero-general-cpo_mapa .contenedor-mapa .contenedor-legend {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
}
#tablero-general-cpo_mapa .contenedor-mapa .contenedor-legend .mapa_legend {
  max-width: 50%;
  width: 50%;
  margin: 25px 0px 0px 0px;
}
#tablero-general-cpo_mapa .contenedor-mapa .contenedor-legend .mapa_legend > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap-reverse;
}
#tablero-general-cpo_mapa .contenedor-mapa .contenedor-legend .mapa_legend > div p {
  margin: 0px 10px 0px 10px ;
}
#tablero-general-cpo_mapa .locations-table {
  padding: 16px 24px;
  width: 100%;
  overflow: auto !important;
}
#tablero-general-cpo_mapa .locations-table .ant-table-wrapper {
  width: 100%;
  overflow: auto;
}
#tablero-general-cpo_mapa .filtros-tabla {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

.seccion_formulario_crear_cargadir {
  background-color: white;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 8px;
}
.col_conector_param {
  padding-left: 5px;
}
.background_new_conector {
  background-color: #f5f6f7;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 150%;
  margin-left: -25%;
}
#tablero-general-cpo_crear {
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
}
.modal-nuevo_conector .ant-modal-footer {
  display: none;
}
.ubicacion_switcher .ant-form-item-control-input-content {
  display: flex;
}
.crear-cargador-map_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
}
.plus_code_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
}
.plus_code_container > div > input {
  margin-bottom: 10px;
}
.plus_code_container > .btn_footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.plus_code_container > .btn_footer > button:first-child {
  margin-right: 5px;
}

.historial-recargas_container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.historial-recargas_container .container-tabs {
  padding: 20px;
}
.historial-recargas_container .container-tabs .recargas_filtro {
  margin: 20px 0px 20px 0px;
}
.historial-recargas_container .container-tabs .recargas_curso_btn {
  position: absolute;
  right: 20px;
  top: 25px;
}
.historial-recargas_container .container-tabs .ant-table {
  overflow: auto;
}
.historial-recargas_container #breadcrumb {
  width: 100%;
}

.container_perfiles #breadcrumb {
  width: 100% !important;
}
.container_perfiles .perfiles_tabs_container {
  padding: 20px;
}

.container-form-perfil {
  text-align: center;
}
.container-form-perfil p {
  font-size: 26px;
  font-weight: bolder;
}
.container-form-perfil form {
  text-align: left;
  padding: 0px 80px 0px 80px;
}
.container-form-perfil form .ant-form-item {
  margin: 0px 0px 8px 0px;
}
.container-form-perfil form .btn-form {
  margin: 60px 0px 0px 0px;
  display: flex !important;
  justify-content: space-evenly;
  flex-direction: row;
}

.container-perfiles button.btn-nuevo_perfil {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
  width: 200px !important;
  margin: 0px 0px 10px 0px;
}
.container-perfiles button.btn-nuevo_perfil .btn-icon {
  margin: 0px 5px 0px 0px;
}
.container-perfiles .card-table .ant-card-body .perfiles-table {
  width: 100%;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_divider {
  margin: 0px 10px 0px 10px;
  border-left: black 0.01em solid;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_action {
  color: #ff812e;
  font-size: 1.2em;
  cursor: pointer;
}
.ant-popover-inner {
  padding: 0px 20px 0px 20px;
}

.container-cargadores .cargadores-tabla-info .ant-card-body {
  display: flex;
  flex-direction: row;
}
.container-cargadores .cargadores-tabla-info .ant-card-body .container-selected_item {
  display: flex;
  align-items: center;
}
.container-cargadores .cargadores-tabla-info .ant-card-body .container-selected_item p {
  margin: 0px 0px 0px 0px;
}
.container-cargadores .cargadores-tabla-info .ant-card-body .container-selected_item .potencia-info {
  margin: 0px 0px 0px 20px !important;
  color: #ee7400;
  font-weight: bolder;
  font-size: 14px;
}
.container-cargadores .cargadores-tabla-info .ant-card-body form {
  margin: 0px 0px 0px auto;
}
.container-cargadores .cargadores-tabla-info .ant-card-body form .ant-form-item-label {
  margin: 0px 0px 0px 0px;
}
.container-cargadores .cargadores-tabla-info .ant-card-body form .ant-form-item-control {
  margin: 0px 0px 0px 0px;
  width: 200px;
}
.container-cargadores .cargadores-tabla-info .ant-card-body form .ant-form-item-required::before {
  display: none !important;
}
.container-cargadores .ant-col:nth-child(n+1) {
  margin: 20px 0px 0px 0px;
}

.container-perfiles button.btn-nuevo_perfil {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
  width: 150px;
  margin: 0px 0px 10px 0px;
}
.container-perfiles button.btn-nuevo_perfil .btn-icon {
  margin: 0px 5px 0px 0px;
}
.container-perfiles .card-table .ant-card-body .perfiles-table {
  width: 100%;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_divider {
  margin: 0px 10px 0px 10px;
  border-left: black 0.01em solid;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_action {
  color: #ff812e;
  font-size: 1.2em;
  cursor: pointer;
}
.ant-popover-inner {
  padding: 0px 20px 0px 20px;
}

.container-form-perfil {
  text-align: center;
}
.container-form-perfil p {
  font-size: 26px;
  font-weight: bolder;
}
.container-form-perfil form {
  text-align: left;
  padding: 0px 80px 0px 80px;
}
.container-form-perfil form .ant-form-item {
  margin: 0px 0px 8px 0px;
}
.container-form-perfil form .btn-form {
  margin: 60px 0px 0px 0px;
  display: flex !important;
  justify-content: space-evenly;
  flex-direction: row;
}

.container-perfiles button.btn-nuevo_perfil {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
  width: 150px;
  margin: 0px 0px 10px 0px;
}
.container-perfiles button.btn-nuevo_perfil .btn-icon {
  margin: 0px 5px 0px 0px;
}
.container-perfiles .card-table .ant-card-body .perfiles-table {
  width: 100%;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_divider {
  margin: 0px 10px 0px 10px;
  border-left: black 0.01em solid;
}
.container-perfiles .card-table .ant-card-body .perfiles-table .style_action {
  color: #ff812e;
  font-size: 1.2em;
  cursor: pointer;
}
.ant-popover-inner {
  padding: 0px 20px 0px 20px;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.main_content {
  margin: 0 auto;
  padding: 24px;
}
.main_content .default_alert {
  margin-bottom: 26px;
}
.steps {
  padding: 10px 28px;
  margin-bottom: 32px;
  font-family: "Raleway", sans-serif;
  color: #39587D;
  background-color: #f4f4f4;
  box-shadow: -2px 1px 6px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.steps > div > div > div > div {
  font-style: normal;
  font-weight: 400;
  color: #376680 !important;
}
.wrapper_loading_alarm {
  flex: 1 1;
}

.card--OzzDX {
  padding: 24px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 10px rgba(109, 92, 76, 0.17);
  border-radius: 6px;
}

.wrapper--1W91b .content--1dMA2 {
  display: flex;
  flex-direction: column;
}
.buttons--2gglh {
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
}
.buttons--2gglh > *:not(:last-child) {
  margin-right: 18px;
}

.short_input--2ur4N {
  max-width: 410px;
}
.large_input--2n2c_ {
  max-width: 410px;
}
.text_area--i1zNZ {
  resize: none;
}
.short_input--2ur4N > div label {
  color: #39587D;
}
.short_input--2ur4N > div div > div > input {
  color: #E9E9E9;
}
.short_input--2ur4N > div div > div > div > div {
  color: #E9E9E9 !important;
}
.large_input--2n2c_ > div label {
  color: #39587D;
}
.large_input--2n2c_ > div div > div > textarea {
  color: #E9E9E9;
}

.container-box {
  background-color: white;
}
.task-container {
  /* border: 1px dotted pink; */
  margin: 0;
  list-style: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 3rem;
  height: -webkit-max-content;
  height: max-content;
  display: inline-flex;
  flex-direction: column;
}
.task-item {
  color: #fa5234;
  box-shadow: 0px 2px 4px #4a4c4e;
  border-radius: 5px;
  padding: 0.3em 0.5em;
  background-color: whitesmoke;
  text-align: center;
  margin: 5px;
  height: 200px;
  border: 2px solid transparent;
}
.task-item:focus {
  outline: none;
  border: 2px solid #4a9af5;
}
.btn-nuevo_perfil {
  right: 0;
  width: 250px;
  font-size: large;
  margin: 0px 0px 10px 0px;
}
.btn-icon {
  margin: 0px 5px 0px 0px;
}
.center-text {
  margin: auto;
}
.cards-text {
  font-size: large !important;
  font-weight: bolder;
}
.row-card {
  margin-bottom: 6vh;
}

.card-content {
  background-color: #FAFAFA;
}
.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
  grid-template-rows: 20% 10% 10% 10% 50%;
  grid-template-areas: "led . . . trash edit" "nombre nombre nombre info1 info1 info1" "charger charger charger info2 info2 info2" "connector connector connector info3 info3 info3" "station station station vehicule vehicule vehicule";
}
.led {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: led;
}
.trash {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: trash;
}
.edit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: edit;
}
.nombre {
  background-color: transparent;
  grid-area: nombre;
  display: flex;
  justify-content: center;
  align-items: center;
}
.charger {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: charger;
}
.connector {
  background-color: transparent;
  grid-area: connector;
  display: flex;
  justify-content: center;
  align-items: center;
}
.station {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: station;
}
.vehicule {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: vehicule;
}
.info-one {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: info1;
}
.info-two {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: info2;
}
.info-three {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  grid-area: info3;
}
.icon-record {
  font-size: 30px;
  color: green;
}
.icon-edit {
  font-size: 15px;
  color: #2D2B2B;
}
.text-label {
  color: #376680;
}
.buttons-edit {
  background-color: transparent !important;
  height: -webkit-min-content;
  height: min-content;
  width: 34px;
  border-color: transparent !important;
}

.table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  list-style: none;
}
.table li {
  height: 234px;
  border: 2px solid;
}

.container-form-perfil {
  text-align: center;
}
.container-form-perfil p {
  font-size: 26px;
  font-weight: bolder;
}
.container-form-perfil form {
  text-align: left;
  padding: 0px 80px 0px 80px;
}
.container-form-perfil form .ant-form-item {
  margin: 0px 0px 8px 0px;
}
.container-form-perfil form .btn-form {
  margin: 60px 0px 0px 0px;
  display: flex !important;
  justify-content: space-evenly;
  flex-direction: row;
}

.container-cargadores button.btn-nuevo_perfil {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
  width: 150px;
  margin: 0px 0px 10px 0px;
}
.container-cargadores button.btn-nuevo_perfil .btn-icon {
  margin: 0px 5px 0px 0px;
}
.container-cargadores .card-table .ant-card-body .perfiles-table {
  width: 100%;
}
.container-cargadores .card-table .ant-card-body .perfiles-table .style_divider {
  margin: 0px 10px 0px 10px;
  border-left: black 0.01em solid;
}
.container-cargadores .card-table .ant-card-body .perfiles-table .style_action {
  color: #ff812e;
  font-size: 1.2em;
  cursor: pointer;
}
.ant-popover-inner {
  padding: 0px 20px 0px 20px;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.main_content {
  margin: 0 auto;
  padding: 24px;
}
.main_content .default_alert {
  margin-bottom: 26px;
}
.steps {
  padding: 10px 28px;
  margin-bottom: 32px;
  font-family: "Raleway", sans-serif;
  color: #39587D;
  background-color: #f4f4f4;
  box-shadow: -2px 1px 6px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.steps > div > div > div > div {
  font-style: normal;
  font-weight: 400;
  color: #376680 !important;
}
.wrapper_loading_alarm {
  flex: 1 1;
}

.container_perfiles #breadcrumb {
  width: 100% !important;
}
.container_perfiles .perfiles_tabs_container {
  padding: 20px;
}

.organizaciones-table {
  padding: 16px 24px;
}
.organizaciones-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  overflow-x: auto;
}
.organizaciones-top {
  margin: 16px 24px;
}
.organizaciones-top .config-usuarios_colbtn_utils {
  box-sizing: content-box;
  justify-content: space-around;
}
.organizaciones-top .config-usuarios_colbtn_utils button {
  padding: 5px;
  box-sizing: content-box;
  float: left;
}
.ant-drawer-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
}
.ant-drawer-footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
.ant-select-selector {
  height: auto !important;
  min-height: 32px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-color: '#D9D9D9' !important;
}
.ant-select-selector > .ant-select-selection-item {
  display: flex;
}
@media (min-width: 1024px) {
  .organizaciones-top .config-usuarios_colbtn_utils {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .organizaciones-top .config-usuarios_colbtn_utils button:nth-child(1) {
    margin: 0px 0px 10px 0px!important;
  }
}

.organizaciones-table {
  padding: 16px 24px;
}
.organizaciones-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  overflow-x: auto;
}
.organizaciones-top {
  margin: 16px 24px;
}
.organizaciones-top .config-clientes_colbtn_utils {
  box-sizing: content-box;
  justify-content: space-around;
}
.organizaciones-top .config-clientes_colbtn_utils button {
  padding: 5px;
  box-sizing: content-box;
  float: left;
}
.columna_crear_formulario {
  padding: 10px;
  text-align: left;
}
.ant-select-selector {
  height: auto !important;
  min-height: 32px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-color: '#D9D9D9' !important;
}
.ant-select-selector .ant-select-selection-placeholder {
  padding: 0px 10px 0px 10px !important;
}
.ant-select-selector > .ant-select-selection-item {
  display: flex;
}
.selector_simple > .ant-select-selector > .ant-select-selection-item {
  padding: 4px 11px !important;
}
@media (min-width: 1024px) {
  .organizaciones-top .config-clientes_colbtn_utils {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .organizaciones-top .config-clientes_colbtn_utils button:nth-child(1) {
    margin: 0px 0px 10px 0px!important;
  }
}

.picker-container {
  width: 100%;
  position: relative;
}
.picker-container .picker-react {
  position: absolute !important;
  left: 0%;
  top: 140%;
  z-index: 1;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.picker-container .picker-react .sketch-picker {
  box-shadow: none !important;
  border: none !important;
}
.picker-container .picker-react button {
  margin: 20px;
}

.container-user-profile .main-container {
  display: flex;
  justify-content: center;
  position: relative;
}
.container-user-profile .main-container .loading-profile {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.container-user-profile .main-container .container-profile {
  width: 80%;
  margin: 20px 0px 0px 0px;
}
.container-user-profile .main-container .container-profile .container-preview {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
}
.container-user-profile .main-container .container-profile .container-preview .input_file {
  overflow-x: auto;
}
.container-user-profile .main-container .container-profile .container-preview .input_file img {
  height: 100% !important;
  padding: 10px;
}
.container-user-profile .main-container .container-profile .container-preview .ant-upload {
  background-color: #e0e0e080;
}
.container-user-profile .main-container .container-profile .container-preview .container-title {
  font-weight: bold;
}
.container-user-profile .main-container .container-profile .container-preview p {
  margin: 0px;
}
.container-user-profile .main-container .container-profile .container-preview .image-preview {
  max-height: 200px;
}
.container-user-profile .main-container .container-profile .form-container {
  padding: 0px 10px 0px 10px;
}
.container-user-profile .main-container .container-profile .form-container .btn-container {
  display: flex;
  justify-content: flex-end;
}
.container-user-profile .main-container .container-profile .form-container .btn-container button {
  margin-left: 10px;
}

#login {
  position: absolute;
  background-color: #F4F4F4;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
}
#login .content {
  min-height: 100%;
  padding: 16px 24px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}
#login .content__logotype {
  color: #376680;
}
#login .content__hashtag {
  color: #2D2B2B;
}
#login .content section {
  flex: 1 1;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
#login .content section header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
#login .content section header > * {
  margin: 0;
}
#login .content section .tabs {
  margin-top: 48px;
}
#login .content section .tabs .ant-tabs-nav-list .ant-tabs-tab {
  margin-right: 60px;
}
#login .content section .tabs .ant-tabs-nav-list .ant-tabs-tab:nth-last-of-type(2) {
  margin-right: 0;
}
#login .content footer {
  text-align: center;
}

#recover-password {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  padding-top: 40px;
  background-color: #F4F4F4;
}
#recover-password .content {
  max-width: 400px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
}
#recover-password .content header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#recover-password .content button {
  align-self: flex-end;
}

.content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.content-wrapper__header {
  background-color: #376680;
  display: flex;
  align-items: center;
  padding: 0;
  height: 60px;
  z-index: 1;
}
.content-wrapper__header > div {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.content-wrapper__main-content {
  position: relative;
  overflow-y: auto;
}
.faq-logo {
  margin-left: 20px;
}
.faq-content {
  background-color: white;
}
.faq-content .faq-contenedor_md {
  justify-content: center;
  margin: 30px 0px 30px 0px;
}

body,
html {
  font-family: "Raleway", sans-serif !important;
}
.ant-progress span.ant-progress-text {
  font-family: Segoe UI, 'Roboto', serif;
}
.ant-statistic-content {
  color: #3F3F3F !important;
}
table tr th.ant-table-selection-column,
.ant-table-selection-column {
  white-space: break-spaces !important;
}

