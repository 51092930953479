@header-height: 60px;

.content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &__header {
      // background-color: @celsia-orange;
      background-color: @primary-color;
      display: flex;
      align-items: center;
      padding: 0;
      height: @header-height;

      // -webkit-box-shadow: 1px 1px 2px 1px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      // -moz-box-shadow: 1px 1px 2px 1px #ccc; /* Firefox 3.5 - 3.6 */
      // box-shadow: 1px 1px 2px 1px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      z-index: 1;

      > div {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__main-content {
      position: relative;
      overflow-y: auto;
    }
}

.faq-logo{
    margin-left: 20px;
}
.faq-content{
  background-color: white;
  .faq-contenedor_md{
    justify-content: center;
    margin: 30px 0px 30px 0px;
  }
}

@celsia-white: #FFFFFF;@celsia-semi-white: #F4F4F4;@celsia-dark: #2D2B2B;@celsia-orange: #FF812D;@celsia-gray: #E9E9E9;@celsia-background-light: #FAFAFA;@celsia-background-medium: #E7E7E7;@celsia-background-dark: #C3C3C3;@celsia-blue: #49A2D8;@celsia-sea-water: #49DCB1;@celsia-dark-blue: #39587D;@celsia-light-gray: #828282;@celsia-red-alert: #CA0045;@primary-color: #376680;@secundary-color: #49A2D8;@success-color: #01C08B;@processing-color: #FFF649;@error-color: #F60141;@normal-color: #E7E7E7;@text-color: #3F3F3F;@standard-padding: 16px 24px;@menu-bg: #F5F6F7;@card-padding-base-sm: @card-padding-base / 2;@card-padding-base: 24px;@fontcustom: #fff;@font-family: "Raleway", sans-serif;@border-radius-base: 10px;